ol {
  list-style: none;
  counter-reset: my-awesome-counter;
}
ol li {
  counter-increment: my-awesome-counter;
  position: relative;
}
ol li::before {
  content: counter(my-awesome-counter) " ";
  font-weight: bold;
  position: absolute;
  left: 15px;
}

.c-question--answer {
  cursor: pointer;
  transition: all 0.2s;
  padding: 1rem;
  margin: 5px;
  background: white;
  border-radius: 4px;
  border: 1px solid #ececec;
  font-size: 1.2rem;
}
.correct {
  color: #3a813c;
  font-weight: bold;
  border-color: #3a813c;
  transform: scale(1.05);
  margin: 10px;
}
.incorrect {
  color: #813a3a;
  text-decoration: line-through;
  border-color: #813a3a;
}
