@import url(https://fonts.googleapis.com/css2?family=Source+Sans+Pro:wght@300;600&display=swap);
* {
  box-sizing: border-box;
}
html,
body,
#root {
  width: 100%;
  height: 100%;
}
body {
  margin: 0;
  font-family: "Source Sans Pro", sans-serif;
  font-weight: 300;
  letter-spacing: 0.02rem;
  font-size: 28px;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-x: hidden;
  background: #eff2fc;
  color: #192e4c;
}
#root {
  display: flex;
  justify-content: center;
  align-items: center;
}
.button {
  margin: 1.6rem 0;
  background: #ff5666;
  color: white;
  font-weight: bold;
  text-transform: uppercase;
  border: none;
  font-size: 1rem;
  padding: 0.7rem 1.5rem;
  border-radius: 5px;
}

.app {
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  max-width: 800px;
  height: 100%;
  max-height: 600px;
  padding: 0 7%;
}

ol {
  list-style: none;
  counter-reset: my-awesome-counter;
}
ol li {
  counter-increment: my-awesome-counter;
  position: relative;
}
ol li::before {
  content: counter(my-awesome-counter) " ";
  font-weight: bold;
  position: absolute;
  left: 15px;
}

.c-question--answer {
  cursor: pointer;
  transition: all 0.2s;
  padding: 1rem;
  margin: 5px;
  background: white;
  border-radius: 4px;
  border: 1px solid #ececec;
  font-size: 1.2rem;
}
.correct {
  color: #3a813c;
  font-weight: bold;
  border-color: #3a813c;
  transform: scale(1.05);
  margin: 10px;
}
.incorrect {
  color: #813a3a;
  text-decoration: line-through;
  border-color: #813a3a;
}

