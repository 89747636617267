@import url("https://fonts.googleapis.com/css2?family=Source+Sans+Pro:wght@300;600&display=swap");
* {
  box-sizing: border-box;
}
html,
body,
#root {
  width: 100%;
  height: 100%;
}
body {
  margin: 0;
  font-family: "Source Sans Pro", sans-serif;
  font-weight: 300;
  letter-spacing: 0.02rem;
  font-size: 28px;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-x: hidden;
  background: #eff2fc;
  color: #192e4c;
}
#root {
  display: flex;
  justify-content: center;
  align-items: center;
}
.button {
  margin: 1.6rem 0;
  background: #ff5666;
  color: white;
  font-weight: bold;
  text-transform: uppercase;
  border: none;
  font-size: 1rem;
  padding: 0.7rem 1.5rem;
  border-radius: 5px;
}
